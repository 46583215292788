import * as React from 'react'
import { graphql } from 'gatsby'
import * as prismicH from '@prismicio/helpers'
import { PrismicRichText } from '@prismicio/react'

import Layout from '../components/Layout'
import Seo from '../components/Seo'

const NotFoundPage = ({ data }) => {
    const document = data.prismicErrorPage?.data

    return (
        <Layout>
            <Seo
                title={
                    document.seo_title.text ||
                    prismicH.asText(document.page_title.richText)
                }
                description={document.seo_description?.text}
            />
            <div className="mx-auto px-6 pt-12 max-w-7xl">
                <div className="mb-4">
                    <h1 className="text-3xl md:text-4xl font-light text-gray-700 leading-snug md:leading-tight">
                        {prismicH.asText(document.page_title?.richText)}
                    </h1>
                </div>
                <div className="pb-20 prose prose-lg max-w-none text-gray-600">
                    <PrismicRichText
                        field={document.page_content?.richText}
                        //serializeHyperlink={PrismicLink}
                    />
                </div>
            </div>
        </Layout>
    )
}

export default NotFoundPage

export const query = graphql`
    query ErrorPageQuery {
        prismicErrorPage {
            data {
                page_title {
                    richText
                }
                seo_title {
                    text
                }
                seo_description {
                    text
                }
                page_content {
                    richText
                }
            }
        }
    }
`
